import { format } from "timeago.js"

// ECMAScript 6 support?
if (Array.from) {
  const nodes = Array.from(document.querySelectorAll(".timeago"))
  nodes.forEach(element => {
    const text = element.innerText
    element.innerText = format(text)
    element.setAttribute("title", text)
  })
}
