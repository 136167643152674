// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/site'
import '../timestamps.js'

import Vue from "vue/dist/vue.esm"

// Log-Meldung verhindern
Vue.config.productionTip = false

import SubmitForm from "../submit-form.vue"
Vue.component("submit-form", SubmitForm)

new Vue({
  el: '#submit',
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
