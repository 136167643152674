<template>
    <div v-if="running">
        <div class="progress">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" v-bind="progressbar(added)"></div>
            <div class="progress-bar progress-bar-striped bg-info"    role="progressbar" v-bind="progressbar(duplicate)"></div>
            <div class="progress-bar progress-bar-striped bg-danger"  role="progressbar" v-bind="progressbar(failed)"></div>
        </div>
        <ol>
            <li v-for="item in items" :key="item.input">
                <code>{{ item.address }}</code> ({{ item.status }}<span v-if="item.error">: {{ item.error }}</span>)
            </li>
        </ol>
    </div>
    <form @submit.prevent="submit" v-else>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <div class="form-group">
            <textarea class="form-control" ref="textarea" rows="5" required></textarea>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-primary">
                Submit
            </button>
        </div>
    </form>
</template>

<script>

import axios from "axios"

// IPv4/IPv6-Adresse
// https://stackoverflow.com/a/34529037
const regexp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/


export default {
    data() {
        return {
            running:  false,
            items:    [],
            added:     0,
            duplicate: 0,
            failed:    0,
            error:     null,
        }
    },
    methods: {
        progressbar(current) {
            const max = this.items.length
            const now = (current / max)
            return {
                'style':        `width: ${now*100}%`,
                'aria-valuemin': 0,
                'aria-valuemax': max,
                'aria-valuenow': now,
            }
        },
        submit() {
            this.running = true
            const lines = this.$refs.textarea.value.split("\n")

            for (const line of lines) {
                line = line.trim()
                if (line == "") {
                    continue
                }
                if (line.match(regexp)) {
                    this.items.push({address: line, status: 'pending'})
                } else {
                    this.items.push({address: line, status: 'error', error: 'invalid IP address'})
                    this.failed++
                }
            }

            if (this.items.length == 0) {
                this.error = "No addresses/hostnames found"
                this.running = false
                return
            }

            this.process()
        },

        async process() {
            for (const item of this.items) {
                if (item.error) {
                    continue
                }
                try {
                    item.status = "checking"
                    await axios.post("/api/nameservers", `address=${item.address}`)
                    item.status = "added"
                    this.added++
                } catch(err) {
                    item.status = "error"
                    if (err.response && err.response.status==409) {
                        this.duplicate++
                    } else {
                        this.failed++
                    }
                    if (err.response) {
                        err = err.response.data
                    }
                    item.error  = err
                }
            }
        }
    }
}
</script>

<style>

</style>
